@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	padding: rem(60) 0 rem(40) 0;

	h1 {
		margin: 0 0 rem(40) 0;

		> img {
			margin: 0 auto;
		}
	}

	h2 {
		font-size: rem(42);
		font-weight: bold;
		letter-spacing: letter-spacing(400);
		margin: 0;
		text-indent: text-indent(400);
	}
}

main {
	h3 {
		font-sizs: rem(16);
		font-weight: normal;
		letter-spacing: letter-spacing(400);
		line-height: line-height(28, 16);
		margin: rem(30) 0;
		text-indent: text-indent(400);
	}

	form {
		margin: 0 auto;
		max-width: rem(630);

		.thanks {
			letter-spacing: letter-spacing(400);
			text-indent: text-indent(400);
		}

		.inputs {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin-bottom: rem(25);

			@include phone-down {
				display: block;
			}

			input {
				border: 1px solid $color-white;
				border-radius: 0;
				color: $color-gray-2;
				display: block;
				flex: 0 0 calc(50% - #{rem(12.5)});
				font-size: rem(14);
				letter-spacing: letter-spacing(400);
				line-height: 1;
				padding: rem(15);
				text-indent: text-indent(400);
				width: 100%;

				&:focus {
					border-color: $color-gray-2;
				}

				@include placeholder {
					color: $color-gray-2;
					text-transform: uppercase;
				}

				@include phone-down {
					margin-bottom: rem(25);
				}
			}
		}

		button {
			background: $color-gray-2;
			border: 0;
			border-radius: 0;
			color: $color-white;
			font-size: rem(14);
			letter-spacing: letter-spacing(400);
			line-height: 1;
			padding: rem(14) rem(48);
			text-align: center;
			text-indent: text-indent(400);
			text-transform: uppercase;

			@include hover-focus {
				background: $color-white;
				color: $color-gray-2;
			}
		}

		.disclaimer {
			font-size: rem(8);
			letter-spacing: letter-spacing(400);
			line-height: line-height(14, 8);
			margin-top: rem(15);
			text-indent: text-indent(400);
		}
	}
}

footer {
	padding: rem(65) 0 rem(30) 0;

	.socials {
		list-style: none;
		margin: 0 0 rem(25) 0;
		padding: 0;

		> li {
			display: inline-block;
			font-size: rem(20);
			vertical-align: middle;

			+ li {
				margin-left: rem(12);
			}

			> a {
				color: $color-white;
				opacity: 1;

				@include hover-focus {
					opacity: 0.8;
				}
			}
		}
	}

	.copyright {
		color: $color-gray;
		font-size: rem(9);
		letter-spacing: letter-spacing(400);
		line-height: line-height(18, 9);
		text-indent: text-indent(400);

		p {
			margin: 0;

			a {
				color: $color-gray;

				@include hover-focus {
					color: $color-white;
				}
			}
		}
	}
}
